.mediaContainer{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: rgba(0,0,0,.8);
    height: 800px;
    border-radius: 5px;
  }
  
  .content{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 80%;
    padding: 4px
  }
  
  .pagination{
    position: absolute;
    z-index: 2;
    top: 74%;
    color: white;
    background-color: rgba(0,0,0,.8);
    border: 1px solid white;
    padding: 8px;
  }

  .caption{
    position: absolute;
    z-index: 10;
    top: 98%;
    color: white;
    background-color: rgba(0,0,0,.8);
    border: 1px solid white;
    padding: 8px;
  }
  
  .fabClose{
      position: absolute;
      z-index: 2;
      left: 97%;
      bottom: 93%;
  }
  
  .fabLeft{
    position: absolute;
    z-index: 1;
    right: 92%;
  }
  
  .fabRight{
    position: absolute;
    z-index: 1;
    left: 92%;
  }
  
  .mediaMain{
    max-width: 100%;
    max-height: calc(100% - 8px);
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    position: absolute;
    z-index: 2;
  }
  
  .mediaMainPrev{
    max-width: 100%;
    max-height: 100%;
    -webkit-transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -o-transition: opacity .5s ease-in-out;
    transition: opacity .5s ease-in-out;
    z-index: 2;
  }
  
  .mediaOpacity1{
    opacity: 1;
  }
  
  .mediaOpacity0{
    opacity: 0;
  }
  
  .media {
      padding: 4px;
      width: 20%;
      height: 100%;
    }
  
  .media:hover  {
  
      background-color:#000;
      opacity:0.7;
      cursor: pointer;
  
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
       -ms-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
           transition: all 0.2s linear;
  }

.scroll{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 20%;
    padding: 4px;
}