.mediaContainer{
  display: flex;
  justify-content: center;
  padding: 32px;
}

.caption{
  position: absolute;
  z-index: 10;
  top: 98%;
  color: white;
  background-color: rgba(0,0,0,.8);
  border: 1px solid white;
  padding: 8px;
}

.content{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pagination{
  position: absolute;
  z-index: 2;
  top: 88%;
  color: white;
  background-color: rgba(0,0,0,.8);
  border: 1px solid white;
  padding: 8px;
}

.fabClose{
    position: absolute;
    z-index: 1000;
    left: 97%;
    bottom: 93%;
}

.fabLeft{
  position: absolute;
  z-index: 1;
  right: 97%;
}

.fabRight{
  position: absolute;
  z-index: 1;
  left: 97%;
}

.mediaMain{
  max-width: 100%;
  max-height: 600px;
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
  position: absolute;
  z-index: 2;
}

.mediaMainPrev{
  max-width: 100%;
  max-height: 600px;
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
  z-index: 2;
}

.mediaOpacity1{
  opacity: 1;
}

.mediaOpacity0{
  opacity: 0;
}

.media {
    padding: 4px;
    width: 200px;
    height: 200px;
  }

.media:hover  {

    background-color:#000;
    opacity:0.7;
    cursor: pointer;

    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
     -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
         transition: all 0.2s linear;
}