@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
.ErrorMsg {
    color:red;
    width: 100%;
    text-align: center;
}

.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Container form {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Container h1{
    font-family: 'Pacifico', cursive;
    text-align: center;
    color: rgb(255, 193, 7);
}

.Logo{
    max-width: 50%;
    padding: 64px;

}