body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'MurrayHill';
  src: local('MurrayHill'), url(./fonts/murrayhillboldregular.ttf) format('truetype');
}
 
/* full calendar classes */
.fc-day:hover {
    background: #e7f8fc!important;
    cursor: pointer;
}

.fc-event-title {
  overflow: hidden!important;
  margin-top: 20px!important;
}

.fc-event {
  overflow: hidden!important;
  background: #FFFFFF!important;
}

.fc-event-today {
  background: #fffadf!important;
}

.fc-event:hover {
  background: #e7f8fc!important;
}

/* Styles for the black rectangle and published text */
.black-rectangle {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 0px;
  padding-left: 16px;
  padding-right: 6px;
  border-radius: 4px;
  font-size: 10px;
}

.published-text {
  margin-bottom: 2px;
}

/* Additional styles for the event checkmark */
.fc-event-checkmark {
  width: 16px;
  height: 16px;
  /*background-color: black;*/
  border-radius: 50%;
  padding: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}


/* react quill classes */
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before { content: '12px'; font-size: 12px !important; }
.ql-picker-label[data-value="12px"]::before { content: '12px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before { content: '14px'; font-size: 14px !important; }
.ql-picker-label[data-value="14px"]::before { content: '14px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before { content: '18px'; font-size: 18px !important; }
.ql-picker-label[data-value="18px"]::before { content: '18px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before { content: '24px'; font-size: 24px !important; }
.ql-picker-label[data-value="24px"]::before { content: '24px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="30px"]::before { content: '30px'; font-size: 30px !important; }
.ql-picker-label[data-value="30px"]::before { content: '30px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before { content: '36px'; font-size: 36px !important; }
.ql-picker-label[data-value="36px"]::before { content: '36px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before { content: '48px'; font-size: 48px !important; }
.ql-picker-label[data-value="48px"]::before { content: '48px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="60px"]::before { content: '60px'; font-size: 60px !important; }
.ql-picker-label[data-value="60px"]::before { content: '60px' !important; }

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="72px"]::before { content: '72px'; font-size: 72px !important; }
.ql-picker-label[data-value="72px"]::before { content: '72px' !important; }

.openAutoCompleteRow {
  background-color: #FFFFFF!important;
}

.openAutoCompleteRow:hover {
  background-color: #ededed!important;
  cursor: pointer;
}

.MuiTableRow-root:nth-child(2n) {
   background-color: #ededed;
};

