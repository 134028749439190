.ButtonGroup button{
    
    border-radius: 16px;
    margin: 0px 8px;

}
.ButtonGroup{
    display:flex;
    flex-direction: row;
    flex-flow: row;
}

.CardInfo{
    display:flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: left;
    width: 100%;
}

.Column{
    display:flex;
    justify-content: center;
    align-items: top;
    width: 100%;
}

.Container{
    height: 100%;
    max-width: 1200px;
    padding:32px;
    background-color: #ffffff;
    display: flex;
    border-radius: 8px;
    justify-content: space-between;
}

.Description{
    white-space: pre-wrap;
}

.ProductName{
    font-size: 2em;
    padding-top: 32px;
    padding-bottom: 8px;
}

.QtyInput {
    width:200px;
}   

.QtyInput input{
    color:#000;
    font-size: 2em;
    text-align:center;
}

.TypeLabel{
    text-transform: capitalize;
    padding: 8px 0px;
}
.VariantSelection{
    padding: 32px 0px;

}

.Price {
    font-size: 1.5em;
    padding: 16px 0px;
}

.ProductImage{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding-right:32px;
}

@media (max-width: 600px) {

    .ButtonGroup{
        display:grid;
        grid-template-columns: repeat(2, 50%);
    }

    .Container{
        flex-direction: column;
    }

    .Column{
        display:flex;
    }

    .ProductImage{
        padding-right:0px;
        margin-top:0px;
        padding-top:0px;
        padding-bottom:0px;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .Column img{
        padding: 0px;
        height: 250px;
        width: 100%;
        display: block;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        margin: auto;
    }

    .QtyInput {
        max-width: 256px;
    }


}

@media (max-width: 600px) {
    .ButtonGroup{
        display:grid;
        grid-template-columns: initial;
    }

    .QtyInput {
        width:100%;
    }
    .ProductImage{
        padding-right:0px;
        margin-top:0px;
        padding-top:0px;
        padding-bottom:0px;
        position: relative;
    }
    .Container{
        height: 100%;
        max-width: 1200px;
        padding:22px;
        padding-top:4px;
        padding-bottom:1px;
        background-color: #ffffff;
        display: flex;
        border-radius: 8px;
        justify-content: space-between;
    }
    .ProductName{
        font-size: 1.4em;
        padding-top: 10px;
        padding-bottom: 2px;
    }
    .Description {
        font-size: 14px;
    }
}